<template>
  <div class="container ml-detail-document mb-5" v-if="item">
    <div class="breadcrumd-custom"><nuxt-link to="/tai-lieu">Tài liệu </nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Khảo sát sự biến thiên và vẽ đồ thị hàm phân thức hữu tỉ</span></div>
    <div class="row align-items-center">
      <div class="col-sm-9">
        <div class="library-name font-weight-bold text-break">{{item.name}}</div>
      </div>
      <div class="order-1 col-lg-9 order-lg-1">
        <div class="library-action position-relative">
          <span class="active text-primary">
            <span v-html="require('~/assets/mlearn/icons/document/view.svg?raw')"></span>
            {{ item.number_view }}
          </span>
          <span class="pointer" :class="{'active text-primary': item.is_saved}" @click="openDropdown">
            <span v-html="require('~/assets/mlearn/icons/document/save.svg?raw')"></span>
            <span>{{ item.is_saved ? 'Đã lưu' : 'Lưu' }}</span>
          </span>
          <Playlist field="file_id" :item="item" :playlist="playlist" :open="open" @close="open = false" @addData="addData" @showError="showError" class-button="bottom-0" />
        </div>
      </div>
      <div class="order-3 col-lg-3 order-lg-2">
        <div class="pt-3 pb-2 pt-lg-0 pb-lg-0 library-care text-center">Có thể bạn quan tâm</div>
      </div>
      <div class="order-2 col-lg-9 order-lg-3">
        <div class="library-preview-file">
          <ViewPdf :link="item.url" height="100%"/>
        </div>
      </div>
      <div class="order-4 col-lg-3 order-lg-4 align-self-start">
        <div class="library-relation-file scrollbar">
          <div class="item display-html display-2-line pointer" v-for="value in relations" :key="value.id" @click="checkBeforeRedirect(value)">{{value.name}}</div>
        </div>
      </div>
    </div>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import cookieParser from 'cookieparser'
import Resource from '~/common/api/resource'
import Playlist from '~/components/mlearn/playlist/index.vue'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
const relationResource = new Resource('library/file/related')
const videoResource = new Resource('library/file/getBySlug')
const playlistResource = new Resource('library/playlist')
const playlistFileResource = new Resource('library/playlist/file')
export default {
  components: {
    PlaylistNotify,
    Playlist
  },
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const data = await videoResource.list({ slug: params.slug }, token)
      .catch(() => {
        return error({ statusCode: 404, title: 'File không tồn tại', message: 'File đã bị xóa hoặc bị báo cáo vi phạm.' })
      })
    if (!data) {
      return error({ statusCode: 404, title: 'File không tồn tại', message: 'File đã bị xóa hoặc bị báo cáo vi phạm.' })
    }
    const item = data.data
    return {
      item,
      playlist: [],
      relations: [],
      open: false,
      processing: false,
      flagNotify: false,
      textNotify: ''
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  created () {
    this.getRelation()
  },
  mounted () {
    this.loadPlayList()
  },
  methods: {
    addData (form) {
      const message = `Đã lưu vào  "<b>${form.playlist_name}</b>"`
      if (form.playlist_id) {
        delete form.playlist_name
      }
      if (this.processing) { return }
      this.flagNotify = false
      this.processing = true
      playlistFileResource.store(form)
        .then((res) => {
          if (res.status === 200) {
            this.item.is_saved = true
            this.textNotify = message
            this.flagNotify = true
            this.open = false
            this.loadPlayList()
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    showError (error) {
      this.textNotify = error
      this.flagNotify = true
    },
    UnSave () {
      if (this.processing) { return }
      playlistFileResource.destroy(this.item.id)
        .then((res) => {
          this.item.is_saved = false
          this.textNotify = 'Đã bỏ lưu'
          this.flagNotify = true
          this.loadPlayList()
        })
        .finally(() => {
          this.processing = false
        })
    },
    async loadPlayList () {
      if (!this.user) { return }
      const { data } = await playlistResource.list({ type: 'doc' })
      this.playlist = data
    },
    async getRelation () {
      const data = await relationResource.list({ id: this.item.id })
      this.relations = data.data
    },
    handerRedirect (redirect = []) {
      let data = null
      if (redirect.length) {
        data = {}
        redirect.forEach((ele) => {
          data[ele] = this.item[ele]
        })
      }
      this.$store.dispatch('setStoreRedirect', data)
      this.$router.push('/tai-lieu')
    },
    checkBeforeRedirect (item) {
      if (!this.user) {
        this.$bvModal.show('modal-login')
        return
      }
      this.$helper.checkUserBuySub(this, item.is_free, '/tai-lieu/' + item.slug)
    },
    openDropdown () {
      if (!this.user) { // chưa đăng nhập
        this.$bvModal.show('modal-login')
        return
      }
      if (this.item.is_saved) {
        this.UnSave()
        return
      }
      this.open = true
    }
  }
}
</script>
<style lang="scss" scoped>
  .ml-detail-document{
    @media (max-width:576px) {}
    .library-name{
      font-size: 28px;
      @media (max-width:1366px) {
        font-size: 25px;
      }
      @media (max-width:576px) {
        font-size: 18px;
      }
    }
    .library-action{
      margin:16px 0;
      font-size: 16px;
      @media (max-width:1366px) {
        margin:10px 0;
        font-size: 15px;
      }
      @media (max-width:576px) {
        margin:5px 0;
        font-size: 14px;
      }
      > span{
        margin-right: 35px;
        @media (max-width:1366px) {
          margin-right: 20px;
        }
        @media (max-width:576px) {
          margin-right: 15px;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
    .library-care{
      font-weight: 600;
      font-size: 16px;
      @media (max-width:1366px) {
        font-size: 15px;
      }
      @media (max-width:576px) {
        font-size: 14px;
      }
    }
    .library-preview-file{
      background: var(--white);
      border: 1px solid #C4C4C4;
      border-radius: 8px;
      height: 800px;
      max-height: 100vh;
      @media (max-width:1366px) {
        height: 600px;
      }
      @media (max-width:576px) {
        height: 500px;
      }
      .pdf-viewer{
        max-width: 100%;
        overflow: hidden;
        min-width: 300px;
        @media (max-width:1366px) {
          min-width: 300px;
        }
        @media (max-width:576px) {
          min-width: 300px;
        }
      }
    }
    .library-relation-file{
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(174, 174, 174, 0.34);
      border-radius: 8px;
      width:100%;
      max-height:50vh;
      min-height:380px;
      padding:20px 20px 20px 16px;
      @media (max-width:1366px) {
        min-height:300px;
        padding:18px 18px 18px 10px;
      }
      @media (max-width:576px) {
        min-height:200px;
        padding:16px;
      }
      .item{
        color: #65676B;
        margin-bottom: 20px;
        font-size: 16px;
        @media (max-width:1366px) {
          margin-bottom: 15px;
          font-size: 15px;
        }
        @media (max-width:576px) {
          margin-bottom: 10px;
          font-size: 14px;
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
</style>
